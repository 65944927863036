/* @import '~materialize-css/dist/css/materialize.min.css'; */
input.yellow-input {
  border-bottom: 1px solid #fff !important;
  box-shadow: 0 1px 0 0 #fff !important;
}

input.yellow-input + label {
  color: #ffffff !important;
}

input.yellow-input:focus + label {
  color: #ffeb3b !important;
}

input.yellow-input:focus {
  border-bottom: 1px solid #ffeb3b !important;
  box-shadow: 0 1px 0 0 #ffeb3b !important;
}

.typeLink {
  color: "blue";
}

.typeLink:hover {
  color: "darkblue";
}
