.App {
  text-align: center;
}

html,
body,
div,
span,
a,
li,
td,
th {
  font-family: Roboto, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Ubuntu, Cantarell, "Open Sans", sans-serif;
}

.ant-menu-item-selected {
  background-color: rgb(5, 66, 150) !important;
}
.ant-btn-primary {
  background-color: rgb(5, 66, 150) !important;
}

.ant-btn-dangerous {
  background-color: #e64322 !important;
}
.ant-layout-sider-children {
  background-color: rgb(5, 43, 120) !important;
}
.ant-menu-submenu-title {
  background-color: rgb(5, 43, 120) !important;
}

.demo-logo-vertical {
  height: 15px !important;
  background-color: rgb(5, 66, 150) !important;
}

.ant-menu-sub {
  background-color: rgb(5, 43, 120) !important;
}
.ant-menu-inline {
  background-color: rgb(5, 43, 120) !important;
}

.ant-menu-item-active {
  background-color: rgb(5, 43, 120) !important;
}

.ant-menu-item-active {
  background-color: rgb(5, 53, 158) !important;
}

.ant-menu-submenu-open {
  background-color: rgb(5, 43, 120) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-select-selection-search-input {
  color: rgba(0, 0, 0, 0.75) !important;
}

.ant-select-selection-item {
  color: rgba(0, 0, 0, 0.75) !important;
}

.ant-select-item-option-content {
  white-space: wrap !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* td.ant-table-cell {
  text-align: center;
} */
.typeLink {
  color: rgb(0, 84, 187);
}

.typeLink:hover {
  color: rgb(0, 115, 255);
  cursor: pointer;
}

.logotype {
  display: none;
}

@media print {
  .logotype {
    display: block !important;
  }

  .noprint {
    display: none !important;
  }
}

.noRadius {
  border-radius: 0;
}
.parallelogram {
  width: 15vw;
  z-index: -1;
  min-width: 45px;
  height: 64px;
  background: #1e55a1;
  -webkit-transform: skew(45deg);
  /* -moz-transform: skew(45deg);
  -o-transform: skew(45deg); */
}

.vignette:after {
  box-shadow: inset 0px 0px 85px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
}

.container {
  max-width: 1140px;
  width: 100%;
  margin: auto;
}

.maintext {
  color: #626262;
  /* line-height: 1.7; */
}

.listItem:hover {
  background-color: rgb(229, 229, 229);
}

.addButton:hover {
  cursor: pointer;
  padding: "24px";
}

.ant-card-meta-title.cardsImage {
  color: #ffffff !important;
}

.cardsImage:hover {
  filter: none !important;
  position: "fixed";
  /* min-height: 300;
  display: "block";
  margin-left: "auto";
  margin-right: "auto"; */
  /* transform: style 0.3s; */
}

@media screen and (max-width: 1000px) {
  .cardsImage {
    filter: none !important;
    position: "fixed";
  }
  /* .ant-image .ant-image-mask {
    opacity: 1 !important;
  } */
}

.headerTitle {
  position: fixed;
  left: 50%;
  right: 50%;
  margin-left: 0px;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1280px) {
  .headerTitle {
    margin-left: 110px;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c8c8c8;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.leftCol {
  background-color: #e4e4e4 !important;
}
