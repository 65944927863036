.ant-image-img:hover .preview-remove {
  opacity: 1;
}
.preview-remove {
  opacity: 1;
  width: 20px;
  height: 20px;
  position: absolute;
  /* right: 0; */
  /* top: 0; */
  font-weight: bold;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.22s;
}

.equipmentList td {
  padding: "2px 2px !important";
  border-radius: 0px;
}

.rangeDatePicker
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.rangeDatePicker
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.rangeDatePicker .ant-picker-panels > *:last-child {
  display: none !important;
}

.rangeDatePicker .ant-picker-panel-container,
.rangeDatePicker .ant-picker-footer {
  width: 280px !important;
}

.rangeDatePicker .ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.notFull {
  background-color: #ff000029;
}

.customize-mask {
  opacity: 1;
}
.customize-mask .anticon {
  opacity: 1;
}

.rct-hl-even {
  touch-action: none; /* Disable touch scrolling */
}

.rct-hl-odd {
  touch-action: none; /* Disable touch scrolling */
}
