/* CSS file for WorkerTimeline component */

/* Base styles for tasks */
.timeline-task {
  border-radius: 4px;
  padding: 4px;
  color: white;
  font-size: 12px;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflow */
  text-overflow: ellipsis; /* Adds ellipsis (...) for overflow text */
}

/* Styles for planned tasks */
.planned-task {
  background-color: #4a90e2; /* Blue background */
  border: 1px solid #357abd; /* Darker blue border */
}

/* Styles for done tasks */
.done-task {
  background-color: #7ed321; /* Green background */
  border: 1px solid #5ba015; /* Darker green border */
}

/* Styles for in-progress tasks */
.in-progress-task {
  background-color: #f5a623; /* Orange background */
  border: 1px solid #d48806; /* Darker orange border */
}

/* Styles for other tasks */
.other-task {
  background-color: #9b9b9b; /* Grey background */
  border: 1px solid #7d7d7d; /* Darker grey border */
}

/* Additional styling for timeline headers */
.timeline-header {
  background-color: #f0f2f5; /* Light grey background */
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
}

/* Layout and responsiveness */
.timeline-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Header styling */
.timeline-header-container {
  background: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

/* Content area styling */
.timeline-content {
  margin: 20px;
  overflow-x: auto;
  background-color: #ffffff; /* White background for content */
}

.rct-header-root .rct-dateHeader-primary {
  background-color: blue; /* Set your desired blue color */
  color: white; /* Set the text color for readability */
}

/* Change the color of secondary headers (e.g., month, day) */
.rct-header-root .rct-dateHeader-secondary {
  background-color: blue; /* Set your desired blue color */
  color: white; /* Set the text color for readability */
}

/* If you want to target all headers together */
.rct-header-root .rct-dateHeader {
  background-color: rgb(123, 123, 123); /* Set your desired blue color */
  color: white; /* Set the text color for readability */
}

.rct-header-root {
  background-color: rgb(
    123,
    123,
    123
  ) !important; /* Set your desired blue color */
  color: white; /* Set the text color for readability */
}

.rct-scroll {
  touch-action: none;
  overflow: hidden;
}
